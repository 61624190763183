import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import 'element-plus/dist/index.css'
import './assets/css/reset.css'
import './assets/css/common.css'
import router from './router'
import store from './store'
import directive from '@/utils/directive'

const app = createApp(App)
// 全局注册自定义指令
Object.keys(directive).forEach(item => {
    app.directive(item, directive[item])
})
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(store)
app.use(router)
app.mount('#app')